import React, { useContext } from 'react'
import { Switch } from '@mui/material'
import { usePartialUpdateUser } from '../../api/user'
import MessageContext from '../../contexts/MessageContext'

export interface UserStatusSwitchProps {
  userId: number
  checked: boolean
}

const UserStatusSwitch: React.FC<UserStatusSwitchProps> = ({ userId, checked }) => {
  const addMessage = useContext(MessageContext)
  const partialUpdateUser = usePartialUpdateUser()

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.checked ? 'enabled' : 'disabled'

    partialUpdateUser.mutateAsync({ id: userId, data: { account_status: newValue } }).catch((err) => {
      addMessage({
        id: Date.now(),
        message: err.response?.data?.message || err.message
      })
    })
  }

  return <Switch checked={checked} onChange={handleToggle} />
}

export default UserStatusSwitch
