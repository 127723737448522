import { type ObjectValues } from '../types'

export const TOKEN_TYPE = {
  REFRESH_TOKEN: 'auth_token',
  ACCESS_TOKEN: 'refresh_token'
} as const

type TokenType = ObjectValues<typeof TOKEN_TYPE>

export default {
  getToken: (tokenType: TokenType): string | null => localStorage.getItem(tokenType),
  setToken: (tokenType: TokenType, token: string): void => localStorage.setItem(tokenType, token),
  removeToken: (tokenType: TokenType): void => localStorage.removeItem(tokenType)
}
