import { type Blog, ROUTES } from '../../types'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { type ColumnDef } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

const columns: Array<ColumnDef<Blog>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'name',
    header: 'Name'
  },
  {
    accessorKey: 'url',
    header: 'URL'
  },
  {
    accessorKey: 'company_id',
    header: 'Company Id',
    cell: (cell) => {
      const value = cell.getValue() as number
      return value ? (
        <Link target="_blank" rel="noopener noreferrer" to={`${ROUTES.COMPANIES}/${value}`}>
          {value}
        </Link>
      ) : (
        ''
      )
    }
  },
  {
    accessorKey: 'plugin_version',
    header: 'Plugin Version'
  },
  {
    accessorFn: (row) => moment(row.updated_at).format('YYYY/MM/DD HH:mm:ss'),
    header: 'Last Connection'
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.edit?.(cell.getValue() as number)
          }}
        >
          <Edit />
        </IconButton>
      </Box>
    )
  }
]

export default columns
