import React from 'react'
import { Box, Stack, Tabs, Tab } from '@mui/material'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import CompanyInfo from './CompanyInfo'
import { useParams } from 'react-router-dom'
import CompanyConfig from './CompanyConfig'

const tabs = [
  { label: 'Info', value: 'info' },
  { label: 'Config', value: 'config' }
]

const Company: React.FC = () => {
  const params = useParams()
  const companyId = Number(params.id)
  const [tab, setTab] = React.useState(tabs[0].value)
  return (
    <div>
      <PageHeaderText>Company #{companyId}</PageHeaderText>
      <Box display="flex" padding={4}>
        <Stack flex={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs value={tab} onChange={(event, value) => setTab(value)}>
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          {tab === 'info' && (
            <Box marginTop={2}>
              <CompanyInfo companyId={companyId} />
            </Box>
          )}
          {tab === 'config' && (
            <Box marginTop={2}>
              <CompanyConfig companyId={companyId} />
            </Box>
          )}
        </Stack>
      </Box>
    </div>
  )
}

export default Company
