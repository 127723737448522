import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useCompany, useUpdateCompany } from '../../api/company'
import MessageContext from '../../contexts/MessageContext'
import { type Company as CompanyType, ManagementRole, ROUTES } from '../../types'
import Empty from '../Empty/Empty'
import { Box, Button, Stack, Switch, Typography, Grid, CircularProgress } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import CompanyForm from '../../components/companyForm/CompanyForm'
import CreditsForm from '../../components/creditsForm/CreditsForm'
import * as Yup from 'yup'
import CompanyManagerAssigner from '../../components/companyManagerAssigner/CompanyManagerAssigner'
import CompanyCSStageAssigner from '../../components/companyCSStageAssigner/CompanyCSStageAssigner'
import CompanyPlanAssigner from '../../components/companyPlanAssigner/CompanyPlanAssigner'
import CreditRenewalsForm from '../../components/creditRenewalsForm/CreditRenewalsForm'
import Spinner from '../../components/spinner/Spinner'

const companyInfoSchema = Yup.object({
  reset: Yup.boolean().required().default(false),
  name: Yup.string().required(),
  user: Yup.object({
    id: Yup.number().required(),
    account_status: Yup.string().oneOf(['enabled', 'disabled']).required()
  }).required(),
  credit: Yup.object({
    id: Yup.number().min(0).required(),
    company_id: Yup.number().min(0).required(),
    ideas_limit: Yup.number().min(0).required(),
    images_limit: Yup.number().min(0).required(),
    frames_limit: Yup.number().min(0).required(),
    posts_limit: Yup.number().min(0).required(),
    keywords_limit: Yup.number().min(0).required(),
    keywords_stats_limit: Yup.number().min(0).required(),
    keywords_tracked_limit: Yup.number().min(0).required(),
    pages_analyzed_limit: Yup.number().min(0).required(),
    ideas_used: Yup.number().min(0).required(),
    images_used: Yup.number().min(0).required(),
    frames_used: Yup.number().min(0).required(),
    posts_used: Yup.number().min(0).required(),
    keywords_used: Yup.number().min(0).required(),
    keywords_stats_used: Yup.number().min(0).required(),
    keywords_tracked_used: Yup.number().min(0).required(),
    pages_analyzed_used: Yup.number().min(0).required()
    // keywords_stats_updated_at: Yup.date(),
    // keywords_stats_updated_at_prev: Yup.date()
  }).required()
})

interface CompanyInfoProps {
  company: CompanyType
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company }) => {
  const updateCompany = useUpdateCompany()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<CompanyType & { reset: boolean }> = useFormik<CompanyType & { reset: boolean }>({
    initialValues: {
      ...company,
      reset: false
    },
    enableReinitialize: true,
    validationSchema: companyInfoSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updateCompany
        .mutateAsync(values)
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex">
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">Info</Typography>
              </Box>
              <Stack marginTop={2}>
                <CompanyForm formik={formik} />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box>
              <Typography variant="h4">CS</Typography>
              <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#eee" padding={2} borderRadius={2}>
                <Stack spacing={2} width={'100%'}>
                  <CompanyManagerAssigner role={ManagementRole.CS_MANAGER} value={company?.managers?.[ManagementRole.CS_MANAGER]?.id} id={company?.id} />
                  <CompanyCSStageAssigner value={company?.cs_stage} id={company?.id} />
                  <CompanyPlanAssigner value={company?.plan} id={company?.id} />
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box>
              <Typography variant="h4">Credits</Typography>
              <Stack marginTop={2}>
                <CreditsForm formik={formik} />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box>
              <Typography variant="h4">Blogs</Typography>
              <Box paddingY={2}>
                {company.blogs?.map((blog) => (
                  <Link key={blog.id} to={`${ROUTES.BLOGS}/${blog.id}`}>
                    <Typography variant="h6">
                      {blog.name} ({blog.url})
                    </Typography>
                  </Link>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box>
              <Typography variant="h4">Credit Renewals</Typography>
              <Stack marginTop={2}>
                <CreditRenewalsForm formik={formik} />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Stack alignItems="flex-end">
              <Box display="flex" alignContent="center" flexWrap="unset" justifyContent="flex-end">
                <Typography variant="body2" display="flex" alignItems="center">
                  Reset used credits on save
                </Typography>
                <Switch name="reset" checked={formik.values.reset} onChange={formik.handleChange} />
              </Box>
              <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
                {formik.isSubmitting && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

const Container: React.FC<{ companyId: number }> = ({ companyId }) => {
  const { data, isLoading, error } = useCompany(companyId)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong Company Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <CompanyInfo company={data} />
  return <Empty message={message} />
}
export default Container
