import React from 'react'
import css from '../CSDashboard.module.css'
import { useCSManagersStats } from '../../../api/management'
import { Table, TableBody, TableRow, TableCell, TableHead, Box } from '@mui/material'
import { type CSManagerStat } from '../../../types'
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table'
import columns from './Columns'
import Spinner from '../../../components/spinner/Spinner'

interface TableProps {
  data: CSManagerStat[]
}
const TableContent: React.FC<TableProps> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table className={css.table}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell, cellIndex) => (
              <TableCell key={cell.id} width={cellIndex > 1 ? '14%' : undefined}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const CSStages: React.FC = () => {
  const { data, isLoading } = useCSManagersStats()

  if (isLoading) return <Spinner marginY={8} />

  return data?.length ? (
    <Box>
      <TableContent data={data} />
    </Box>
  ) : (
    <Box textAlign="center">No CS managers found</Box>
  )
}

export default CSStages
