import * as React from 'react'
import { Box, CircularProgress } from '@mui/material'

interface SpinnerProps {
  marginY?: number
  size?: number
}

const Spinner: React.FC<SpinnerProps> = ({ marginY = 4, size = 32 }) => {
  return (
    <Box marginY={marginY} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={size} />
    </Box>
  )
}

export default Spinner
