import instance from './client'
import { type User, type ManagementRole, type CSStage, type Plan, type CSManagerStat, type CSManagerContent } from '../types'
import { type AxiosError } from 'axios'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'

export const useManagementRole = (userId: number): UseQueryResult<{ role: string | null }, AxiosError> => {
  return useQuery<unknown, AxiosError, { role: ManagementRole | null }>({
    queryKey: ['management-role', userId],
    queryFn: async () => {
      return await instance.get('/management/roles', { params: { user_id: userId } }).then((data) => data.data)
    },
    enabled: !!userId
  })
}

export const useSetManagementRole = (): UseMutationResult<any, AxiosError, { userId: number; role?: ManagementRole }> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, { userId: number; role?: ManagementRole }>({
    mutationKey: ['set-management-role'],
    mutationFn: async ({ userId, role }) =>
      await instance
        .post('/management/roles', {
          user_id: userId,
          role
        })
        .then((data) => data.data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['management-role', variables.userId])
    }
  })
}

export const useManagers = (role: ManagementRole): UseQueryResult<Array<Partial<User>>, AxiosError> => {
  return useQuery<unknown, AxiosError, Array<Partial<User>>>({
    queryKey: ['managers', role],
    queryFn: async () => {
      return await instance.get('/management/managers', { params: { role } }).then((data) => data.data?.users)
    },
    enabled: !!role
  })
}

export const useCSManagersStats = (): UseQueryResult<CSManagerStat[], AxiosError> => {
  return useQuery<unknown, AxiosError, CSManagerStat[]>({
    queryKey: ['cs-managers-stats'],
    queryFn: async () => {
      return await instance.get('/management/cs-managers/stats').then((data) => data.data?.users)
    }
  })
}

export const useCSManagersContent = (dateFrom: string, dateTo: string): UseQueryResult<CSManagerContent[], AxiosError> => {
  return useQuery<unknown, AxiosError, CSManagerContent[]>({
    queryKey: ['cs-managers-content', dateFrom, dateTo],
    queryFn: async () => {
      return await instance.get('/management/cs-managers/content', { params: { date_from: dateFrom, date_to: dateTo } }).then((data) => data.data?.users)
    }
  })
}

export const useCompanyManagers = (companyId: number): UseQueryResult<Record<any, any>, AxiosError> => {
  return useQuery<unknown, AxiosError, Array<Record<any, any>>>({
    queryKey: ['company-managers', companyId],
    queryFn: async () => {
      return await instance.get(`/management/company/${companyId}/managers`).then((data) => data.data)
    },
    enabled: !!companyId
  })
}

export const useSetCompanyManager = (): UseMutationResult<any, AxiosError, { company_id: number; user_id?: number; role: ManagementRole }> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, { company_id: number; user_id?: number; role: ManagementRole }>({
    mutationKey: ['set-company-manager'],
    mutationFn: async (data) =>
      await instance
        .post(`/management/company/${data.company_id}/managers`, {
          ...data
        })
        .then((data) => data.data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['company-managers', variables.company_id])
      queryClient.invalidateQueries(['company', variables.company_id])
    }
  })
}

export const useSetCompanyCSStage = (): UseMutationResult<any, AxiosError, { company_id: number; stage: CSStage }> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, { company_id: number; stage: CSStage }>({
    mutationKey: ['set-company-cs-stage'],
    mutationFn: async (data) =>
      await instance
        .post(`/management/company/${data.company_id}/stage`, {
          stage: data.stage
        })
        .then((data) => data.data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['company', variables.company_id])
    }
  })
}

export const useSetCompanyPlan = (): UseMutationResult<any, AxiosError, { company_id: number; plan: Plan }> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, { company_id: number; plan: Plan }>({
    mutationKey: ['set-company-plan'],
    mutationFn: async (data) =>
      await instance
        .post(`/management/company/${data.company_id}/plan`, {
          plan: data.plan
        })
        .then((data) => data.data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['company', variables.company_id])
    }
  })
}
