import React, { useContext } from 'react'
import TextField from '@mui/material/TextField'
import { Button } from '@mui/material'
import css from './Login.module.css'
import useLogin from '../../hooks/useLogin'

const Login: React.FC = () => {
  const formik = useLogin()

  return <div className={css.container}>
    <form onSubmit={formik.handleSubmit} className={css.form}>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        fullWidth
        id="password"
        name="password"
        label="Password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.password && !!formik.errors.password}
        helperText={formik.touched.password && formik.errors.password}
      />
      {/* <TextField */}
      {/*  fullWidth */}
      {/*  id="name" */}
      {/*  name="name" */}
      {/*  label="Blog" */}
      {/*  type="text" */}
      {/*  value={formik.values.name} */}
      {/*  onChange={formik.handleChange} */}
      {/*  onBlur={formik.handleBlur} */}
      {/*  error={!!formik.touched.name && !!formik.errors.name} */}
      {/*  helperText={formik.touched.name && formik.errors.name} */}
      {/* /> */}
      <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
        Login
      </Button>
    </form>
  </div>
}

export default Login
