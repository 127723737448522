import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'

const Loader: React.FC = () => {
  return (
    <Box sx={{ width: '100%', position: 'absolute' }}>
      <LinearProgress />
    </Box>
  )
}

export default Loader
