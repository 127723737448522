import { useContext, useMemo, useState } from 'react'
import { type FormikHelpers } from 'formik'

import { type Template } from '../../types'
import { useUpdateTemplate } from '../../api/template'
import MessageContext from '../../contexts/MessageContext'
import { type TemplateFieldGroup, defaultFieldGroup, fieldGroupsByTab } from './fieldGroups'
import _ from 'lodash'

type UseTemplate = {
  data?: Template
  fields: string[]
  selectedTab: number
  groupedFields: GroupedFields[]
  tabNames: string[]
  onSubmit: (values: Template, helpers: FormikHelpers<Template>) => void
  switchTab: (newTabIdx: number) => void
}

type UseTemplateProps = {
  data: Template
  filter?: string
}

type GroupedFields = {
  group: TemplateFieldGroup
  fields: string[]
}

type Tab = {
  name: string
  match?: (fieldKey: string) => boolean
}

const tabsNamesList: Tab[] = [
  { name: 'general' },
  { name: 'content' },
  { name: 'frame' },
  { name: 'ideas' },
  { name: 'ideas_prompt', match: (fieldKey) => fieldKey.startsWith('ideas_prompt') },
  { name: 'keywords' },
  { name: 'page' },
  { name: 'postv2' },
  { name: 'post' },
  { name: 'mass_actions', match: (fieldKey: string) => ['post_translate', 'post_update', 'post_review'].some((v) => fieldKey.startsWith(v)) }
]

const useTemplate = ({ data, filter }: UseTemplateProps): UseTemplate => {
  const addMessage = useContext(MessageContext)
  const updateTemplate = useUpdateTemplate()
  const [selectedTab, setSelectedTab] = useState(0)

  const tabNames = useMemo<string[]>(() => tabsNamesList.map((tab) => tab.name), [])

  const fields = useMemo<string[]>(() => {
    return data?.params.common ? Object.keys(data.params.common).sort() : []
  }, [data])

  const fieldsByTabMap = useMemo<Record<string, string[]>>(() => {
    const result: Record<string, string[]> = {}
    const remainingFields = [...fields]

    // initialize result object with empty arrays for each tab
    tabsNamesList.forEach((tab) => {
      result[tab.name] = []
    })

    // iterating through tabs in reverse order and checking match methods
    ;[...tabsNamesList].reverse().forEach((tab) => {
      if (tab.name === 'general') return

      const matchedFields = remainingFields.filter((field: string) => tab.match?.(field.toLowerCase()))
      result[tab.name].push(...matchedFields)
      _.pullAll(remainingFields, matchedFields)
    })

    // iterating through tabs in reverse order and checking field prefixes
    ;[...tabsNamesList].reverse().forEach((tab) => {
      if (tab.name === 'general') return

      const matchedFields = remainingFields.filter((field: string) => field.toLowerCase().startsWith(`${tab.name}_`))
      result[tab.name].push(...matchedFields)
      _.pullAll(remainingFields, matchedFields)
    })

    // Add remaining fields to the 'general' tab
    result.general.push(...remainingFields)

    return result
  }, [fields])

  // filter fields to display based on active tab & search term
  const filteredFields = useMemo<string[]>(() => {
    const fieldsForTab = fieldsByTabMap[tabsNamesList[selectedTab].name]

    if (filter) {
      return fieldsForTab.filter((key) => key.toLowerCase().includes(filter.toLowerCase() || ''))
    }

    return [...fieldsForTab]
  }, [fields, selectedTab, filter])

  // get field groups for active tab
  const fieldGroups = useMemo<TemplateFieldGroup[]>(
    () =>
      [...(fieldGroupsByTab[tabsNamesList[selectedTab].name] || []), defaultFieldGroup]
        .reverse()
        .map((group, groupIndex) => ({ ...group, color: groupIndex ? '#9575cd' : '#bdbdbd' }))
        .reverse(),
    [fields, selectedTab]
  )

  // grouping fields
  let fieldsCounter = [...filteredFields]
  const groupedFields = useMemo<GroupedFields[]>((): GroupedFields[] => {
    return fieldGroups.map((fGroup: TemplateFieldGroup): GroupedFields => {
      const groupFields: string[] = []
      fieldsCounter = fieldsCounter.filter((field) => {
        if (fGroup.test(field)) {
          groupFields.push(field)
          return false
        }
        return true
      })

      return { group: fGroup, fields: groupFields }
    })
  }, [filteredFields, fieldGroups])

  const onSubmit = (values: Template, helpers: FormikHelpers<Template>): void => {
    helpers.setSubmitting(true)
    updateTemplate
      .mutateAsync(values)
      .catch((err) => {
        addMessage({
          id: Date.now(),
          message: err.response?.data || err.message
        })
      })
      .finally(() => {
        helpers.setSubmitting(false)
      })
  }

  return {
    data,
    fields: filteredFields,
    groupedFields,
    selectedTab,
    tabNames,
    onSubmit,
    switchTab: setSelectedTab
  }
}

export default useTemplate
