import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { type LoginError, type LoginInput, type LoginResponse } from '../types'
import instance from './client'

const useLogin = (): UseMutationResult<LoginResponse, LoginError, LoginInput, unknown> => {
  return useMutation<LoginResponse, LoginError, LoginInput>({
    mutationFn: async (input) => await instance.post<LoginResponse>('/auth/login', input).then(data => data.data)
  })
}

export default useLogin
