import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'
import MessageContext from '../contexts/MessageContext'
import useLoginApi from '../api/login'
import { type LoginError, ROUTES } from '../types'
import storage from '../utils/storage'

interface FormValues {
  email: string
  password: string
}

const initialValues: FormValues = {
  email: '',
  password: ''
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
})

const useLogin = (): ReturnType<typeof useFormik<FormValues>> => {
  const { setAuthToken } = useContext(AuthContext)
  const addMessage = useContext(MessageContext)
  const navigate = useNavigate()

  const mutation = useLoginApi()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true)

      mutation.mutateAsync(values)
        .then(data => {
          storage.setToken('refresh_token', data.refresh_token)
          setAuthToken(data.api_token)
          navigate(ROUTES.INDEX)
        })
        .catch((error: LoginError) => addMessage({
          id: Date.now(),
          message: error.response?.data.message ?? error.message
        }))
        .finally(() => formikHelpers.setSubmitting(false))
    }
  })

  return formik
}

export default useLogin
