import React, { useContext, useEffect } from 'react'
import MessageContext from '../../contexts/MessageContext'
import { PERMISSION } from '../../types'
import Loader from '../../components/loader/Loader'
import { Box, Button, Stack, Typography } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import NewUserForm, { type NewUserType } from './NewUserForm'
import { useCreateUser } from '../../api/user'
import { locale } from '../../constants'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'

export const newUserSchema = Yup.object({
  email: Yup.string().label('Email').email().required(),
  password: Yup.string().label('Password').required().min(5),
  repeat_password: Yup.string()
    .label('Repeat Password')
    .oneOf([Yup.ref<string>('password')], 'Passwords must match')
    .required(),
  domain: Yup.string().label('Domain').required(),
  blogname: Yup.string().label('Blog Name').required(),
  request_url: Yup.string().label('Request URL').required(),
  role: Yup.string().label('Role').oneOf([PERMISSION.OWNER, PERMISSION.ADMIN, PERMISSION.MEMBER], 'Invalid role').required()
})

const NewUser: React.FC = () => {
  const createUser = useCreateUser()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<NewUserType> = useFormik<NewUserType>({
    initialValues: {
      email: '',
      password: '',
      repeat_password: '',
      domain: '',
      blogname: '',
      request_url: '',
      role: PERMISSION.OWNER
    },
    validationSchema: newUserSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      createUser
        .mutateAsync(values)
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.message || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  useEffect(() => {
    // set default company & blog values for admin
    if (formik.values.role === PERMISSION.ADMIN) {
      formik.setValues({
        ...formik.values,
        blogname: 'Admin Account',
        domain: 'wpseoai.com',
        request_url: 'https://wpseoai.com/wp-admin/admin-ajax.php'
      })
    }
  }, [formik.values.role])

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.isSubmitting ? <Loader /> : null}
      <PageHeaderText>New User</PageHeaderText>
      <Box display="flex" padding={4}>
        <Stack flex={4}>
          <Typography variant="h4">Info</Typography>
          <Stack marginTop={2}>
            <NewUserForm formik={formik} />
          </Stack>
          <Stack marginTop={2} alignItems="flex-end">
            <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </form>
  )
}

export default NewUser
