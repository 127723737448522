import React, { useContext, useState } from 'react'
import AuthContext from '../../contexts/AuthContext'
import { useConfig, useUpdateConfig } from '../../api/config'
import Loader from '../../components/loader/Loader'
import { type Config as ConfigType, ROUTES } from '../../types'
import css from './Config.module.css'
import { useFormik } from 'formik'
import { Navigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { Button } from '@mui/material'
import MessageContext from '../../contexts/MessageContext'
import { locale } from '../../constants'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'

interface ConfigProps {
  config: ConfigType
}

type Keys = keyof ConfigType['config']
const getFieldPath = (label: string): string => `config.${label}`

const Config: React.FC<ConfigProps> = ({ config }) => {
  const updateConfig = useUpdateConfig()
  const addMessage = useContext(MessageContext)
  const fields = Object.keys(config.config) as Keys[]
  const formik = useFormik<ConfigType>({
    initialValues: config,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true)
      updateConfig
        .mutateAsync({
          id: config.id,
          ...values.config
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response.data || err.message,
            code: err.response.status
          })
        })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .finally(() => {
          formikHelpers.setSubmitting(false)
        })
    }
  })

  return (
    <div className={css.container}>
      {formik.isSubmitting && <Loader />}
      <PageHeaderText>Config</PageHeaderText>
      <form onSubmit={formik.handleSubmit} className={css.form}>
        {fields.map((field) => (
          <TextField
            disabled={formik.isSubmitting}
            key={field}
            fullWidth
            id={field}
            name={getFieldPath(field)}
            label={field}
            value={formik.values.config[field]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.touched.config?.[field] && !!formik.errors.config?.[field]}
            helperText={formik.touched.config?.[field] && formik.errors.config?.[field]}
          />
        ))}
        <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
          Save
        </Button>
      </form>
    </div>
  )
}
const Container: React.FC = () => {
  const { data: config, isLoading } = useConfig()

  if (isLoading) return <Loader />
  if (config) return <Config config={config} />
  return <Navigate to={ROUTES.INDEX} />
}
export default Container
