import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import MessageContext from '../../contexts/MessageContext'
import { PERMISSION, type User as UserType } from '../../types'
import Empty from '../Empty/Empty'
import Loader from '../../components/loader/Loader'
import { Box, Button, Stack, Typography } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import { newUserSchema } from '../NewUser/NewUser'
import { usePartialUpdateUser, useUser } from '../../api/user'
import pick from 'lodash/pick'
import UserForm, { type EditableUserType } from '../../components/userForm/UserForm'
import { locale } from '../../constants'
import PasswordUpdateButton from './PasswordUpdateButton'
import AuthContext from '../../contexts/AuthContext'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import ManagementRoleAssigner from '../../components/managementRoleAssigner/ManagementRoleAssigner'

const editableUserSchema = newUserSchema.pick(['email'])

interface UserProps {
  user: UserType
}

const User: React.FC<UserProps> = ({ user }) => {
  const params = useParams()
  const partialUpdateUser = usePartialUpdateUser()
  const addMessage = useContext(MessageContext)
  const { me, logout } = useContext(AuthContext)

  const formik: FormikProps<EditableUserType> = useFormik<EditableUserType>({
    initialValues: {
      ...pick(user, ['email'])
    },
    validationSchema: editableUserSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      partialUpdateUser
        .mutateAsync({ id: user.id, data: values })
        .then(() => {
          // if user updates his own email, logout
          if (me?.id === user.id && values.email !== user.email) {
            logout()
          } else {
            addMessage({
              id: Date.now(),
              message: locale.response.success,
              severity: 'success'
            })
          }
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.message || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <div>
      {formik.isSubmitting ? <Loader /> : null}
      <PageHeaderText>User #{params.id}</PageHeaderText>
      <Box display="flex" padding={4}>
        <Stack flex={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Info</Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              {user?.role === PERMISSION.ADMIN && (
                <Box marginRight={2}>
                  <ManagementRoleAssigner id={Number(params.id)} />
                </Box>
              )}
              <PasswordUpdateButton id={Number(params.id)} />
            </Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Stack marginTop={4} spacing={2}>
              <UserForm formik={formik} />
            </Stack>
            <Stack marginTop={2} alignItems="flex-end">
              <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
                Save
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
    </div>
  )
}

const Container: React.FC = () => {
  const params = useParams()
  const { id } = params
  const number = Number(id)
  const { data, isLoading, error } = useUser(number)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong User Id') as string
  if (isLoading) return <Loader />
  if (data) return <User user={data} />
  return <Empty message={message} />
}
export default Container
