import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { PERMISSION, ROUTES, type User } from '../types'
import storage from '../utils/storage'
import * as userApi from '../api/user'
import MessageContext, { HIDDEN_CREDIT_WARNINGS_KEY } from './MessageContext'
import { useMe } from '../api/user'

interface AuthContextData {
  me: User | undefined
  loading: boolean
  setAuthToken: (value: string) => void
  logout: () => void
}
const defaultContextValue = {
  me: undefined,
  loading: true,
  setAuthToken: () => {},
  logout: () => {}
}
const AuthContext = createContext<AuthContextData>(defaultContextValue)

interface AuthContextProviderProps {
  children: React.ReactNode
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (props) => {
  const addMessage = useContext(MessageContext)
  const [authToken, _setAuthToken] = useState<string | null>(storage.getToken('auth_token'))

  const setAuthToken = useCallback<AuthContextData['setAuthToken']>(async (value) => {
    storage.setToken('auth_token', value)
    _setAuthToken(value)
  }, [])

  const logout = useCallback(() => {
    storage.removeToken('auth_token')
    storage.removeToken('refresh_token')
    localStorage.removeItem(HIDDEN_CREDIT_WARNINGS_KEY)
    window.location.href = ROUTES.LOGIN
  }, [])

  const { data: me, isLoading, error } = useMe(!!authToken)
  useEffect(() => {
    if (error) {
      addMessage({ id: Date.now(), message: error.message, code: error?.response?.status })
    }
  }, [error])

  const loading = !!authToken && !me

  return (
    <AuthContext.Provider
      value={{
        me,
        setAuthToken,
        loading,
        logout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
