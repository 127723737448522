import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Alert, Button, Stack, styled } from '@mui/material'
import moment from 'moment'
type Message = {
  id: number | string
  message: string
  code?: number
  onClick?: () => void
  severity?: 'error' | 'info' | 'success' | 'warning'
  context?: string
}
export type MessageContextData = (message: Message) => void
const MessageContext = createContext<MessageContextData>(() => {})

interface AuthContextProviderProps {
  children: React.ReactNode
}

export const CREDIT_WARNING_CONTEXT = 'credit-warning'
export const HIDDEN_CREDIT_WARNINGS_KEY = 'hideCreditWarningsUntil'

export const MessageContextProvider: React.FC<AuthContextProviderProps> = (props) => {
  const [messages, setMessages] = useState<Message[]>([])
  const [creditWarningsHidden, setCreditWarningsHidden] = useState(() => {
    const timestampUntil = localStorage.getItem(HIDDEN_CREDIT_WARNINGS_KEY)

    // removing key from localStorage if outdated
    if (!timestampUntil || moment().isAfter(moment(timestampUntil))) {
      localStorage.removeItem(HIDDEN_CREDIT_WARNINGS_KEY)
      return false
    }

    return true
  })
  const filteredMessages = useMemo(() => {
    if (creditWarningsHidden) {
      return messages.filter((msg) => msg.context !== CREDIT_WARNING_CONTEXT)
    }

    return [...messages]
  }, [messages, creditWarningsHidden])
  const creditWarningsExist = useMemo(() => messages.some((msg) => msg.context === CREDIT_WARNING_CONTEXT), [messages])

  const addMessage = useCallback((message: Message) => {
    setMessages((prev) => {
      if (!prev.some((n) => n.id === message.id)) {
        return [...prev, message]
      }
      return prev
    })
  }, [])

  const hideCreditWarnings = (): void => {
    localStorage.setItem(HIDDEN_CREDIT_WARNINGS_KEY, moment().add(24, 'hours').toISOString())
    setCreditWarningsHidden(true)
  }

  const showCreditWarnings = (): void => {
    localStorage.removeItem(HIDDEN_CREDIT_WARNINGS_KEY)
    setCreditWarningsHidden(false)
  }

  return (
    <MessageContext.Provider value={addMessage}>
      {props.children}
      {messages.length ? (
        <StackStyled sx={{ zIndex: 10 }}>
          {creditWarningsExist && (
            <div style={{ position: 'absolute', top: '-40px', right: '0' }}>
              {creditWarningsHidden ? (
                <Button size="small" color="info" onClick={showCreditWarnings}>
                  Show Credit Warnings
                </Button>
              ) : (
                <Button size="small" color="info" onClick={hideCreditWarnings}>
                  Hide Credit Warnings For 24 Hours
                </Button>
              )}
            </div>
          )}
          {filteredMessages.map((m) => (
            <AlertStyled
              key={m.id}
              onClick={
                m.onClick
                  ? () => {
                      m.onClick?.()
                      setMessages((prev) => prev.filter((p) => p.id !== m.id))
                    }
                  : undefined
              }
              onClose={() => {
                setMessages((prev) => prev.filter((p) => p.id !== m.id))
              }}
              severity={m.severity ?? 'error'}
            >
              {m.code} {m.message}
            </AlertStyled>
          ))}
        </StackStyled>
      ) : null}
    </MessageContext.Provider>
  )
}

const StackStyled = styled(Stack)`
  position: absolute;
  top: 120px;
  right: 20px;
  gap: 10px;
  width: 400px;
`
const AlertStyled = styled(Alert)`
  display: inline-flex;
  width: content-box;
`

export default MessageContext
