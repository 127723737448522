import { type Keyword, ROUTES } from '../../types'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { BarChart } from '@mui/icons-material'
import { type ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Link } from 'react-router-dom'

const columns: Array<ColumnDef<Keyword>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'target',
    header: 'Domain'
  },
  {
    accessorKey: 'keyword',
    header: 'Keyword'
  },
  {
    accessorKey: 'location',
    header: 'Location'
  },
  {
    accessorKey: 'language',
    header: 'Language'
  },
  {
    accessorKey: 'blog_id',
    header: 'Blog Id',
    cell: (cell) => {
      const blogId = cell.getValue() as number

      if (!blogId) {
        return ''
      }

      return (
        <Box>
          <Link to={`${ROUTES.BLOGS}/${blogId}`} target={'blank'} rel={'noopener noreferrer'}>
            {blogId}
          </Link>
        </Box>
      )
    }
  },
  {
    accessorFn: (row) => (row.values?.length ? row.values[row.values.length - 1][1] : '-'),
    header: 'Position'
  },
  {
    accessorFn: (row) => moment(row.created_at).format(),
    header: 'Created At'
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.ranks?.(cell.getValue() as number)
          }}
        >
          <BarChart />
        </IconButton>
      </Box>
    )
  }
]

export default columns
