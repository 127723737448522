import React from 'react'
import TextField from '@mui/material/TextField'
import { type FormikProps } from 'formik'
import { type Company as CompanyType } from '../../types'
import Select from '@mui/material/Select'
import get from 'lodash/get'
import { MenuItem } from '@mui/material'

const getError = (field: string, formik: CompanyFormProps['formik']): string | undefined => {
  return get(formik.touched.user, field, '') && get(formik.errors.user, field, '')
}
interface CompanyFormProps {
  formik: FormikProps<CompanyType & { reset: boolean }>
}
const CompanyForm: React.FC<CompanyFormProps> = ({ formik }) => (
  <>
    <TextField
      fullWidth
      id="name"
      name="name"
      label="Company Name"
      value={formik.values.name}
      disabled={formik.isSubmitting}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={!!formik.touched.name && !!formik.errors.name}
      helperText={formik.touched.name && formik.errors.name}
    />
    <Select
      style={{ marginTop: 16 }}
      id="user.account_status"
      name="user.account_status"
      defaultValue="enabled"
      value={formik.values.user?.account_status as ''}
      disabled={formik.isSubmitting}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    >
      <MenuItem value={'enabled'}>Enabled</MenuItem>
      <MenuItem value={'disabled'}>Disabled</MenuItem>
    </Select>
  </>
)

export default CompanyForm
