import instance from './client'
import { type ResponseData, type QueryParams, type User } from '../types'
import { type AxiosError } from 'axios'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import { type NewUserType } from '../pages/NewUser/NewUserForm'
import { type UserPasswordUpdate } from '../pages/User/PasswordUpdateButton'

export const useMe = (enabled: boolean): UseQueryResult<User, AxiosError> => {
  return useQuery<unknown, AxiosError, User>({
    queryKey: ['me'],
    queryFn: async () => await instance.get('/users/me').then((data) => data.data),
    enabled
  })
}

export const useUser = (id: number): UseQueryResult<User, AxiosError> => {
  return useQuery<unknown, AxiosError, User>({
    queryKey: ['user', id],
    queryFn: async () => await instance.get('/users/' + id).then((data) => data.data),
    enabled: !!id
  })
}

export const useUsers = (params: QueryParams<User>): UseQueryResult<ResponseData<User>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<User>>({
    queryKey: ['users', params.offset, params.limit, params.search],
    queryFn: async () => await instance.get('/users', { params }).then((data) => data.data)
  })
}

export const useCreateUser = (): UseMutationResult<any, AxiosError, NewUserType> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, NewUserType>({
    mutationKey: ['create-user'],
    mutationFn: async (input) => await instance.post('/users', input).then((data) => data.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['users'])
      queryClient.invalidateQueries(['companies']) // since companies contain information related to users
    }
  })
}

export const usePartialUpdateUser = (): UseMutationResult<User, AxiosError, { id: number; data: Partial<User> }> => {
  const queryClient = useQueryClient()
  return useMutation<User, AxiosError, { id: number; data: Partial<User> }>({
    mutationKey: ['partial-update-user'],
    mutationFn: async ({ id, data: input }) => await instance.patch(`/users/${id}`, input).then((data) => data.data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['users'])
      queryClient.invalidateQueries(['user', variables.id])
      queryClient.invalidateQueries(['companies']) // since companies contain information related to users
    }
  })
}

export const useUpdateUserPassword = (): UseMutationResult<any, AxiosError, { id: number; data: UserPasswordUpdate }> => {
  return useMutation<any, AxiosError, { id: number; data: UserPasswordUpdate }>({
    mutationKey: ['update-user-password'],
    mutationFn: async ({ id, data: input }) => await instance.put(`/users/${id}/password`, input).then((data) => data.data)
  })
}
