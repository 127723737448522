import instance from './client'
import { type Config } from '../types'
import { type AxiosError } from 'axios'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'

export const useConfig = (): UseQueryResult<Config, AxiosError> => {
  return useQuery<unknown, AxiosError, Config>({
    queryKey: ['config'],
    queryFn: async () => await instance.get('/config').then(data => data.data)
  })
}

export type PatchInput = Partial<Config['config']> & { id: number }
export const useUpdateConfig = (): UseMutationResult<Config, AxiosError, PatchInput> => {
  const queryClient = useQueryClient()
  return useMutation<Config, AxiosError, PatchInput>({
    mutationKey: ['patch-config'],
    mutationFn: async ({ id, ...input }) => await instance.patch(`/config/${id}`, input).then(data => data.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['config'], data)
    }
  })
}
