import React, { useMemo } from 'react'
import TextField from '@mui/material/TextField'
import { type FormikProps } from 'formik'
import { PERMISSION } from '../../types'
import Select from '@mui/material/Select'
import { FormControl, InputLabel, MenuItem, Stack, Typography } from '@mui/material'
import startCase from 'lodash/startCase'

export type NewUserType = {
  email: string
  password: string
  repeat_password: string
  role: PERMISSION
  domain: string
  blogname: string
  request_url: string
}

interface NewUserFormProps {
  formik: FormikProps<NewUserType>
}

const NewUserForm: React.FC<NewUserFormProps> = ({ formik }) => {
  const supportedRoles = useMemo(() => {
    const rolesObj: Partial<{
      [K in PERMISSION]: String
    }> = {}
    ;[PERMISSION.OWNER, PERMISSION.MEMBER, PERMISSION.ADMIN].forEach((role) => {
      rolesObj[role] = startCase(role)
    })

    return rolesObj
  }, [])

  return (
    <>
      <Stack marginTop={2} spacing={2}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          fullWidth
          id="repeat_password"
          name="repeat_password"
          label="Repeat Password"
          type="password"
          value={formik.values.repeat_password}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.repeat_password && !!formik.errors.repeat_password}
          helperText={formik.touched.repeat_password && formik.errors.repeat_password}
        />
        <FormControl fullWidth error={formik.touched.role && !!formik.errors.role}>
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select labelId="role-select-label" label="Role" name="role" value={formik.values.role} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            {Object.keys(supportedRoles).map((roleKey) => (
              <MenuItem key={roleKey} value={roleKey}>
                {supportedRoles[roleKey as keyof typeof supportedRoles]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Typography variant="h4" marginTop={3}>
        Blog
      </Typography>
      <Stack marginTop={2} spacing={2}>
        <TextField
          label="Domain"
          name="domain"
          value={formik.values.domain}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.domain && !!formik.errors.domain}
          helperText={formik.touched.domain && formik.errors.domain}
          fullWidth
        />
        <TextField
          label="Blog Name"
          name="blogname"
          value={formik.values.blogname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.blogname && !!formik.errors.blogname}
          helperText={formik.touched.blogname && formik.errors.blogname}
          fullWidth
        />
        <TextField
          label="Request URL"
          name="request_url"
          value={formik.values.request_url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.request_url && !!formik.errors.request_url}
          helperText={formik.touched.request_url && formik.errors.request_url}
          fullWidth
        />
      </Stack>
    </>
  )
}

export default NewUserForm
