import React, { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { PERMISSION, ROUTES } from '../types'
import AuthContext from '../contexts/AuthContext'
import { locale } from '../constants'
import MessageContext from '../contexts/MessageContext'

interface RouteProtectionProps {
  children: React.ReactElement
  permissions?: PERMISSION[]
}

const RouteProtection: React.FC<RouteProtectionProps> = ({ children, permissions }) => {
  const { me, loading, logout } = useContext(AuthContext)
  const addMessage = useContext(MessageContext)

  useEffect(() => {
    if (!loading) {
      if (me && me.role === PERMISSION.OWNER) {
        logout()
      }
      if (me && (!permissions || permissions.includes(PERMISSION.AUTHORIZED))) {
        return
      }
      if (me && permissions?.includes(me.role)) {
        return
      }
      addMessage({ id: Date.now(), message: locale.permissions.e401 })
      addMessage({ id: Date.now() + 100, message: locale.permissions.e403 })
    }
  }, [me, permissions, loading, me])

  if (loading) return null

  if (me && (!permissions?.length || permissions?.includes(PERMISSION.AUTHORIZED))) {
    return children
  }
  if (me && permissions?.includes(me.role)) {
    return children
  }
  if (me) {
    return <Navigate to={ROUTES.INDEX} />
  }
  return <Navigate to={ROUTES.LOGIN} />
}

export default RouteProtection
