import instance from './client'
import { type Keyword, type ResponseData, type PaginatedData } from '../types'
import { type AxiosError } from 'axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface KeywordSearchParams extends PaginatedData {
  company_id: number
  target_search?: string
  keyword_search?: string
}

export const useKeywords = (params: KeywordSearchParams): UseQueryResult<ResponseData<Keyword>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<Keyword>>({
    queryKey: ['keywords', params.limit, params.offset, params?.company_id, params?.target_search, params?.keyword_search],
    queryFn: async () => await instance.post('/ai/company-keywords', { ...params }).then((data) => data.data.data)
  })
}
