import React, { useState } from 'react'
import css from '../CSDashboard.module.css'
import { useCSManagersContent } from '../../../api/management'
import { Table, TableBody, TableRow, TableCell, TableHead, Box, Stack } from '@mui/material'
import { type CSManagerContent } from '../../../types'
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table'
import columns from './Columns'
import Spinner from '../../../components/spinner/Spinner'
import moment from 'moment'
import DatePicker from '../../../components/datePicker/DatePicker'
import { dateToMiddleOfDay } from '../../../utils/date'

interface TableProps {
  data: CSManagerContent[]
}

const TableContent: React.FC<TableProps> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table className={css.table}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell, cellIndex) => (
              <TableCell key={cell.id} width={cellIndex > 1 ? '14%' : undefined}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const CSContent: React.FC = () => {
  const [dateFrom, setDateFrom] = useState(dateToMiddleOfDay(moment().subtract(1, 'day')).toISOString())
  const [dateTo, setDateTo] = useState(dateToMiddleOfDay(moment().subtract(1, 'day')).toISOString())
  const { data, isLoading } = useCSManagersContent(dateFrom, dateTo)

  return (
    <Box>
      <Stack direction="row" spacing={2} paddingX={4} marginTop={'-91px'} marginBottom={'35px'} justifyContent={'flex-end'}>
        <DatePicker
          name="dateFrom"
          label="Start Date"
          value={moment(dateFrom).toDate()}
          onChange={(v) => {
            const newValue = dateToMiddleOfDay(v)
            setDateFrom(newValue.toISOString())
          }}
        />
        <DatePicker
          name="dateTo"
          label="End Date"
          value={moment(dateTo).toDate()}
          onChange={(v) => {
            const newValue = dateToMiddleOfDay(v)
            setDateTo(newValue.toISOString())
          }}
        />
      </Stack>
      {isLoading ? (
        <Spinner marginY={8} />
      ) : data?.length ? (
        <Box>
          <TableContent data={data} />
        </Box>
      ) : (
        <Box textAlign="center">No CS managers found</Box>
      )}
    </Box>
  )
}

export default CSContent
