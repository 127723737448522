import React, { useContext, useState } from 'react'
import css from './PasswordUpdateButton.module.css'
import MessageContext from '../../contexts/MessageContext'
import { Box, Button, CircularProgress, Stack, IconButton, TextField, Modal } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import { useUpdateUserPassword } from '../../api/user'
import { locale } from '../../constants'
import { newUserSchema } from '../NewUser/NewUser'
import { type NewUserType } from '../NewUser/NewUserForm'
import { Close } from '@mui/icons-material'

export const passwordUpdateSchema = newUserSchema.pick(['password', 'repeat_password'])

export type UserPasswordUpdate = Pick<NewUserType, 'password' | 'repeat_password'>

interface PasswordUpdateButtonProps {
  id: number
}

const PasswordUpdateButton: React.FC<PasswordUpdateButtonProps> = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const updatePassword = useUpdateUserPassword()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<UserPasswordUpdate> = useFormik<UserPasswordUpdate>({
    initialValues: {
      password: '',
      repeat_password: ''
    },
    validationSchema: passwordUpdateSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updatePassword
        .mutateAsync({ id, data: values })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.message || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
          handleClose()
        })
    }
  })

  const handleClose = (): void => {
    setIsModalOpen(false)
    formik.resetForm()
  }

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} variant="outlined" color="secondary" style={{ width: 230 }}>
        Change Password
      </Button>
      <Modal open={isModalOpen} onClose={handleClose} sx={{ position: 'relative' }}>
        <div className={css.modalContainer}>
          <Box className={css.modalHeader} display="flex" justifyContent="space-between" alignItems="center">
            <h2>Change Password</h2>
            <IconButton className={css.closeButton} color="inherit" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <div className={css.modalBody}>
            <form onSubmit={formik.handleSubmit}>
              <Stack marginTop={2} spacing={2}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  fullWidth
                  id="repeat_password"
                  name="repeat_password"
                  label="Repeat Password"
                  type="password"
                  value={formik.values.repeat_password}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.repeat_password && !!formik.errors.repeat_password}
                  helperText={formik.touched.repeat_password && formik.errors.repeat_password}
                />
                <Stack marginTop={2} alignItems="flex-end">
                  <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
                    {updatePassword.isLoading && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Update
                  </Button>
                </Stack>
              </Stack>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PasswordUpdateButton
