import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { type LookerStudioType as LookerStudio, type Blog as BlogType } from '../../types'
import { type FormikProps, useFormik } from 'formik'
import MessageContext from '../../contexts/MessageContext'
import { locale } from '../../constants'
import { Box, Button, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import Spinner from '../../components/spinner/Spinner'
import Empty from '../Empty/Empty'
import { useUpdateLookerStudio, useLookerSudio } from '../../api/blog'
import css from '../Blogs/Blogs.module.css'

type EditableLookerStudioType = Pick<LookerStudio, 'iframe'>

const editableReportSchema = Yup.object({
  iframe: Yup.string().label('Iframe')
})

interface LookerStudioProps {
  blogId: number
  iframe: string
}

const LookerStudioInfo: React.FC<LookerStudioProps> = ({ blogId, iframe }) => {
  const [currentIframe, setCurrentIframe] = useState(iframe)
  const updateLookerStudio = useUpdateLookerStudio()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<EditableLookerStudioType> = useFormik<EditableLookerStudioType>({
    initialValues: {
      iframe: currentIframe
    },
    validationSchema: editableReportSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updateLookerStudio
        .mutateAsync({ blogId, iframe: values.iframe })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
          setCurrentIframe(values.iframe)
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.message || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <div>
      <Box>
        <Stack flex={4}>
          <form onSubmit={formik.handleSubmit}>
            <Stack marginTop={4} spacing={2}>
              <TextField
                fullWidth
                id="iframe"
                name="iframe"
                label="Iframe"
                value={formik.values.iframe}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.iframe && !!formik.errors.iframe}
                helperText={formik.touched.iframe && formik.errors.iframe}
              />
            </Stack>
            <Stack marginTop={2} alignItems="flex-end">
              <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
                Save
              </Button>
            </Stack>
          </form>
        </Stack>
        { currentIframe && (
          <Box marginTop={4}>
            <div className={css.lookerStudio} dangerouslySetInnerHTML={ { __html: currentIframe } } />
          </Box>
        )}
      </Box>
    </div>
  )
}

const LookerStudioContainer: React.FC<{ blogId: number }> = ({ blogId }) => {
  const { data, isLoading, error } = useLookerSudio(blogId)
  const message = (error ?? 'Wrong Blog Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <LookerStudioInfo blogId={blogId} iframe={data?.iframe || ''} />
  return <Empty message={message} />
}

export default LookerStudioContainer
