import React from 'react'
import TextField from '@mui/material/TextField'
import { type FormikProps } from 'formik'
import { type Company as CompanyType, CreditType } from '../../types'
import { Box, InputAdornment } from '@mui/material'
import get from 'lodash/get'

const getFieldPath = (label: string): string => `credit.${label}`

const getError = (field: string, formik: CreditsFormProps['formik']): string | undefined => {
  return get(formik.touched.credit, field, '') && get(formik.errors.credit, field, '')
}
interface CreditsFormProps {
  formik: FormikProps<CompanyType & { reset: boolean }>
}
const fields = [
  { key: CreditType.IDEAS_LIMIT, name: getFieldPath(CreditType.IDEAS_LIMIT), used_key: CreditType.IDEAS_USED, label: 'Ideas' },
  { key: CreditType.FRAMES_LIMIT, name: getFieldPath(CreditType.FRAMES_LIMIT), used_key: CreditType.FRAMES_USED, label: 'Frames' },
  { key: CreditType.POSTS_LIMIT, name: getFieldPath(CreditType.POSTS_LIMIT), used_key: CreditType.POSTS_USED, label: 'Posts' },
  { key: CreditType.KEYWORDS_LIMIT, name: getFieldPath(CreditType.KEYWORDS_LIMIT), used_key: CreditType.KEYWORDS_USED, label: 'Keywords' },
  { key: CreditType.KEYWORDS_STATS_LIMIT, name: getFieldPath(CreditType.KEYWORDS_STATS_LIMIT), used_key: CreditType.KEYWORDS_STATS_USED, label: 'Keywords Stats' },
  { key: CreditType.KEYWORDS_TRACKED_LIMIT, name: getFieldPath(CreditType.KEYWORDS_TRACKED_LIMIT), used_key: CreditType.KEYWORDS_TRACKED_USED, label: 'Keywords Tracked' },
  { key: CreditType.PAGES_ANALYZED_LIMIT, name: getFieldPath(CreditType.PAGES_ANALYZED_LIMIT), used_key: CreditType.PAGES_ANALYZED_USED, label: 'Pages Analyzed' },
  { key: CreditType.IMAGES_LIMIT, name: getFieldPath(CreditType.IMAGES_LIMIT), used_key: CreditType.IMAGES_USED, label: 'Images' }
]
const CreditsForm: React.FC<CreditsFormProps> = ({ formik }) => {
  return (
    <>
      {fields.map((field) => (
        <Box key={field.key} display="flex" marginBottom={2} alignItems="center">
          <TextField
            type="number"
            fullWidth
            id={field.key}
            name={field.name}
            label={field.label}
            value={formik.values.credit?.[field.key]}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!getError(field.key, formik)}
            helperText={getError(field.key, formik)}
          />
          <TextField
            style={{ maxWidth: 100, paddingLeft: 20 }}
            InputProps={{
              startAdornment: <InputAdornment position="start"> used / </InputAdornment>
            }}
            variant="standard"
            type="number"
            id={field.used_key}
            name={getFieldPath(field.used_key)}
            value={formik.values.credit?.[field.used_key]}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!getError(field.used_key, formik)}
            helperText={getError(field.used_key, formik)}
          />
        </Box>
      ))}
    </>
  )
}

export default CreditsForm
