import * as React from 'react'
import { Box, FormControl, type InputBaseProps, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { CSStage } from '../../types'

interface CSStageSelectorProps {
  value?: CSStage
  label?: string
  onChange?: (value?: CSStage) => void
  size?: InputBaseProps['size']
  style?: React.CSSProperties
  allowEmpty?: boolean
  emptyValueText?: string
}

const CSStageOptions = [
  {
    label: 'Onboarding',
    value: CSStage.ONBOARDING
  },
  {
    label: 'Customer Success',
    value: CSStage.CUSTOMER_SUCCESS
  },
  {
    label: 'Stuck',
    value: CSStage.STUCK
  },
  {
    label: 'Fight',
    value: CSStage.FIGHT
  },
  {
    label: 'Churn',
    value: CSStage.CHURN
  }
]

const EMPTY_VALUE = '-'

const CSStageSelector: React.FC<CSStageSelectorProps> = ({ value, label, onChange = (v) => {}, size, style, allowEmpty = false, emptyValueText }) => {
  const finalLabel = label ?? 'CS Stage'

  return (
    <Box bgcolor="white" style={style ?? { width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="company-cs-stage-select-label">{finalLabel}</InputLabel>
        <Select
          labelId="company-cs-stage-select-label"
          label={finalLabel}
          id="company-cs-stage-select"
          name="company-cs-stage-select"
          value={value ?? (allowEmpty ? EMPTY_VALUE : CSStage.ONBOARDING)}
          onChange={(e) => onChange(e.target.value === EMPTY_VALUE ? undefined : (e.target.value as CSStage))}
          variant="outlined"
          color="primary"
          size={size ?? 'medium'}
          fullWidth={true}
        >
          {allowEmpty && <MenuItem value={EMPTY_VALUE}>{emptyValueText ?? '-'}</MenuItem>}
          {CSStageOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CSStageSelector
