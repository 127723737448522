import React from 'react'
import { Button, Dialog as DialogMUI, DialogProps as DialogMuiProps, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

interface DialogProps {
  open: boolean
  handleClose: () => void
  content: React.ReactNode | string
  title: React.ReactNode | string
  handleOk: () => void
  fullWidth?: boolean
  classes?: Record<string, string>
}
const Dialog: React.FC<DialogProps> = ({
  open, handleClose, title, content, handleOk, fullWidth, classes
}) => (
  <DialogMUI
    fullWidth={fullWidth}
    open={open}
    classes={classes}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>Cancel</Button>
      <Button onClick={handleOk}>Ok</Button>
    </DialogActions>
  </DialogMUI>
)

export default Dialog
