import React from 'react'
import { Link } from 'react-router-dom'
import { type CSManagerStat, CSStage, ROUTES } from '../../../types'
import type { CellContext, ColumnDef } from '@tanstack/react-table'
import css from '../CSDashboard.module.css'

const StageHeaders = {
  [CSStage.ONBOARDING]: 'Onboarding',
  [CSStage.CUSTOMER_SUCCESS]: 'Customer Success',
  [CSStage.STUCK]: 'Stuck',
  [CSStage.FIGHT]: 'Fight',
  [CSStage.CHURN]: 'Churn'
}

const stageColumns = Object.values(CSStage).map((stage) => ({
  accessorKey: `stats.${stage}`,
  header: StageHeaders[stage],
  cell: (cell: CellContext<CSManagerStat, unknown>) => (
    <Link to={ROUTES.COMPANIES + `?cs_manager=${cell.row.original.id}&cs_stage=${stage}`} target={'blank'} rel={'noopener noreferrer'} className={css.stageLink}>
      {cell.getValue() as string}
    </Link>
  )
}))

const columns: Array<ColumnDef<CSManagerStat>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: (cell) => (
      <Link to={ROUTES.USERS + '/' + cell.row.original.id} target={'blank'} rel={'noopener noreferrer'} className={css.userLink}>
        {cell.getValue() as string}
      </Link>
    )
  },
  ...stageColumns
]

export default columns
