import React, { useState } from 'react'
import * as Yup from 'yup'
import { type FormikProps, useFormik } from 'formik'
import Loader from '../../../../components/loader/Loader'
import useTemplate from '../../../../hooks/useTemplate'
import { useDebounce } from 'use-debounce'
import { Box, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { type Template as TemplateType, type TemplateRecord } from '../../../../types'
import TemplateActions from '../TemplateActions/TemplateActions'
import PageHeaderText from '../../../../components/pageHeader/PageHeaderText'
import css from './Template.module.css'
import cls from 'classnames'

const templateSchema = Yup.object({
  name: Yup.string(),
  params: Yup.object({
    common: Yup.object({}).required()
  }).required()
})

type TemplateProps = {
  template: TemplateType
}

const Template: React.FC<TemplateProps> = ({ template }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200)
  const { groupedFields, selectedTab, tabNames, onSubmit, switchTab } = useTemplate({ data: template, filter: debouncedSearchTerm })
  const [cursorPositions, setCursorPositions] = useState<Partial<Record<string, number>>>({})
  const formik: FormikProps<TemplateType> = useFormik<TemplateType>({
    initialValues: { ...template },
    validationSchema: templateSchema,
    onSubmit
  })

  const handleCursorPosition = (key: string, event: React.SyntheticEvent<HTMLTextAreaElement>): void => {
    setCursorPositions(() => ({
      [key]: (event.target as any).selectionStart
    }))
  }

  const insertValueAtCursor = (key: string, value: string): void => {
    // adding variable to the text
    const currentValue = getFieldValue(formik.values.params.common[key])
    const cursorPosition = cursorPositions[key] ?? currentValue.length
    const newValue = currentValue.slice(0, cursorPosition) + value + currentValue.slice(cursorPosition)
    formik.setFieldValue(`params.common.${key}.value`, newValue)

    // re-focusing the TextField and set cursor position after insertion
    setTimeout(() => {
      const textField = document.querySelector(`[name="params.common.${key}.value"]`) as HTMLInputElement
      if (textField) {
        textField.focus()
        const newPosition = cursorPosition + value.length
        textField.setSelectionRange(newPosition, newPosition)
      }
    }, 0)
  }

  const getFieldValue = (fieldData: TemplateRecord): string => fieldData.value || fieldData.defaultTemplate

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      {formik.isSubmitting ? <Loader /> : null}
      <PageHeaderText>Templates</PageHeaderText>
      <Box display="flex" paddingInline={2} paddingBlock={4}>
        <Stack flex={4}>
          <Box display="flex" justifyContent="space-between">
            <Tabs value={selectedTab} onChange={(_, newValue) => switchTab(newValue)} aria-label="Tabs" variant="scrollable" scrollButtons="auto">
              {tabNames.map((name, index) => (
                <Tab key={index} label={name} />
              ))}
            </Tabs>
            <TextField style={{ width: 575, alignSelf: 'flex-end' }} size="small" id="search" name="search" label="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>

          {groupedFields?.length ? (
            groupedFields?.map((data) => {
              if (!data.fields.length) {
                return null
              }

              return (
                <Stack key={data.group.name} borderColor={data.group.color} marginTop={2} className={cls(css.templateFieldGroup, { [css.defaultFieldGroup]: data.group?.isDefault })}>
                  <Typography position={'absolute'} bgcolor={data.group.color} className={css.templateFieldGroupTitle}>
                    {data.group.name}
                  </Typography>
                  <Stack>
                    {data.fields.map((key) => {
                      const fieldValue = getFieldValue(formik.values.params.common[key])
                      return (
                        <Stack key={key} marginBottom={2}>
                          <Box display="flex" marginBottom={1} gap={0.5} flexWrap="wrap">
                            {formik.values.params.common[key].placeholders.map((p) => (
                              <Typography className={css.templateVariable + (fieldValue.includes(p) ? '' : ' ' + css.templateVariableActive)} key={p} variant={'caption'} onClick={() => insertValueAtCursor(key, p)}>
                                {p}
                              </Typography>
                            ))}
                          </Box>
                          <TextField
                            multiline
                            fullWidth
                            id={key}
                            name={`params.common.${key}.value`}
                            label={key.toUpperCase()}
                            value={fieldValue}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onSelect={(event: React.SyntheticEvent<any>) => handleCursorPosition(key, event)}
                            error={!!formik.touched?.params?.common?.[key]?.value && !!formik.errors?.params?.common?.[key]?.value}
                            helperText={!!formik.touched?.params?.common?.[key]?.value && formik.errors?.params?.common?.[key]?.value}
                          />
                        </Stack>
                      )
                    })}
                  </Stack>
                </Stack>
              )
            })
          ) : (
            <Box display="flex" justifyContent="center" color="#aaa" marginY={2}>
              Templates not found
            </Box>
          )}

          <TemplateActions dirty={formik.dirty} isSubmitting={formik.isSubmitting} resetValues={formik.handleReset} />
        </Stack>
      </Box>
    </form>
  )
}

export default Template
