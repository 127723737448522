import React, { useMemo, useState } from 'react'
import css from './Keywords.module.css'
import { Table, TableBody, TableRow, TableCell, TableHead, Pagination, Box, Stack, MenuItem, Select, FormControl, InputLabel, TextField, Button } from '@mui/material'
import { type Keyword } from '../../types'
import columns from './Columns'
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table'
import Dialog from '../../components/dialog/Dialog'
import { useKeywords } from '../../api/keyword'
import { useParams } from 'react-router-dom'
import TimelineChart from '../../components/timelineChart/TimelineChart'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'

const LIMIT = 5

interface TableProps {
  data: Keyword[]
  setToView: (id: number) => void
}

interface SearchProps {
  target_search?: string
  keyword_search?: string
  company_id: number
}

const SEARCH_EMPTY_VALUES: Partial<SearchProps> = {
  target_search: '',
  keyword_search: ''
}

const TableKeywords: React.FC<TableProps> = ({ data, setToView }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      ranks: (id: number) => {
        setToView(id)
      }
    }
  })

  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Keywords: React.FC = (props) => {
  const [toView, setToView] = useState<Keyword | undefined>()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState<number>(LIMIT)
  const [clearedFormKey, setClearedForm] = useState<number>(0)
  const params = useParams()
  const searchInitialValues = useMemo(() => {
    return {
      ...SEARCH_EMPTY_VALUES,
      company_id: Number(params.id)
    }
  }, [])
  const [search, setSearch] = useState<SearchProps>({
    ...searchInitialValues
  })
  const { data: { data, total } = { data: [] as Keyword[], total: 0 } } = useKeywords({
    limit,
    offset: (page - 1) * limit,
    ...(Object.entries(search).reduce((acc, [key, value]) => {
      if (value) {
        const formattedValue = typeof value === 'string' ? value.trim() : value
        return { ...acc, [key]: formattedValue }
      }
      return acc
    }, {}) as SearchProps)
  })

  const setDefaultSearch = (): void => {
    setSearch({ ...searchInitialValues })
    setPage(1)
    setClearedForm(Math.random())
  }

  const setSearchParam = (param: string, value: string | number | null): void => {
    setSearch({ ...search, [param]: value })
    setPage(1)
  }

  return (
    <div className={css.container}>
      <PageHeaderText>Keywords</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput} key={clearedFormKey}>
          <TextField
            id="domain"
            style={{ marginLeft: '15px' }}
            label={'Domain'}
            variant="outlined"
            value={search.target_search}
            onChange={(e) => {
              setSearchParam('target_search', e.target.value)
            }}
          />
          <TextField
            id="keyword"
            style={{ marginLeft: '15px' }}
            label={'Keyword'}
            variant="outlined"
            value={search.keyword_search}
            onChange={(e) => {
              setSearchParam('keyword_search', e.target.value)
            }}
          />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setDefaultSearch()
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Keywords per page</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={limit}
              label="Keywords per page"
              onChange={(e) => {
                setLimit(Number(e.target.value))
                setPage(1)
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableKeywords
        data={data}
        setToView={(id) => {
          setToView(data.find((d) => d.id === id))
        }}
      />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
      <Dialog fullWidth open={!!toView} handleClose={() => setToView(undefined)} content={<Content data={toView} />} title={toView ? `Keyword #${toView?.id.toString()}` : ''} handleOk={() => setToView(undefined)} />
    </div>
  )
}

const Content: React.FC<{ data?: Keyword }> = (params) => (
  <Stack>
    <TimelineChart data={params.data?.values ?? []} labels={{ y: 'Position' }} />
  </Stack>
)

export default Keywords
