import { Box, Button } from '@mui/material'
import React from 'react'

type FormActionsProps = {
  dirty: boolean
  isSubmitting: boolean
  resetValues: () => void
}

const TemplateActions: React.FC<FormActionsProps> = ({
  dirty,
  isSubmitting,
  resetValues
}) => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-end" gap={2}>
      <Button
        disabled={!dirty || isSubmitting}
        type="submit"
        variant="contained"
        style={{ width: 230 }}
      >
        Save
      </Button>

      <Button
        disabled={!dirty || isSubmitting}
        type="button"
        variant="contained"
        onClick={resetValues}
        style={{ width: 230 }}
      >
        Reset
      </Button>
    </Box>
  )
}

export default TemplateActions
