import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import moment from 'moment'

interface RankChartProps {
  data: Array<[string, number]>
  labels?: {
    x?: string
    y?: string
  }
}

const TimelineChart: React.FC<RankChartProps> = ({ data, labels }) => {
  const formattedData = data
    .sort((a, b) => moment(a[0]).diff(moment(b[0])))
    .map(([date, value]) => ({
      date: moment(date).format('YYYY-MM-DD'),
      value
    }))

  return (
    <LineChart width={500} height={300} data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" label={{ value: labels?.x ?? 'Date', position: 'insideBottomRight', offset: -5 }} />
      <YAxis label={{ value: labels?.y ?? 'Value', angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      <Line dataKey="value" type="monotone" stroke="#8884d8" label="Rank" />
    </LineChart>
  )
}

export default TimelineChart
