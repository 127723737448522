import * as React from 'react'
import { Box, FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { ManagementRole } from '../../types'
import { useManagementRole, useSetManagementRole } from '../../api/management'
import { useContext } from 'react'
import MessageContext from '../../contexts/MessageContext'
import Spinner from '../spinner/Spinner'

interface ManagementRoleAssignerProps {
  id: number
}

const EMPTY_VALUE = '-'

const ManagementRoleOptions = [
  {
    label: 'No role',
    value: EMPTY_VALUE
  },
  {
    label: 'CS Manager',
    value: ManagementRole.CS_MANAGER
  }
]

const ManagementRoleAssigner: React.FC<ManagementRoleAssignerProps> = ({ id }) => {
  const addMessage = useContext(MessageContext)
  const setManagementRole = useSetManagementRole()
  const { data, isLoading, error } = useManagementRole(id)

  const setRole = (role: string): void => {
    setManagementRole.mutateAsync({ userId: id, role: role === EMPTY_VALUE ? undefined : (role as ManagementRole) }).catch((err: any) => {
      console.error(err)
      addMessage({
        id: Date.now(),
        message: 'Unable to update management role'
      })
    })
  }

  return isLoading ? (
    <Spinner marginY={0} size={24} />
  ) : (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="management-role-select-label">Management Role</InputLabel>
        <Select
          labelId="management-role-select-label"
          label={'Management Role'}
          id="management-role-select"
          name="management-role-select"
          value={data?.role ?? EMPTY_VALUE}
          onChange={(e) => {
            setRole(e.target.value as ManagementRole)
          }}
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ minWidth: '250px' }}
        >
          {ManagementRoleOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ManagementRoleAssigner
