import axios from 'axios'
import { type RefreshAuthError, type BaseError } from '../types'
import storage from '../utils/storage'
import useRefreshAuthToken, { rawRefreshAuthToken } from './refresh'

const baseURL = process.env.REACT_APP_BASE_URL
const apiKey = process.env.REACT_APP_API_KEY

const instance = axios.create({
  baseURL
})

instance.interceptors.request.use(function (config) {
  config.headers.Authorization = storage.getToken('auth_token') as string
  config.headers['x-api-key'] = apiKey

  return config
})

instance.interceptors.response.use(
  response => response,
  async (error: BaseError) => {
    const prevRequest = error.config

    if (prevRequest === undefined) return await Promise.reject(error)

    if (
      error.response?.status === 401 &&
      error.response?.data.message === 'Invalid credentials'
    ) {
      const refreshToken = storage.getToken('refresh_token') ?? undefined
      const authToken = storage.getToken('auth_token')

      if (authToken === null) {
        const customError = new Error('Authentication failed. Tokens are missing. Please login again.')
        customError.name = 'AuthenticationError'

        return await Promise.reject(customError)
      }

      await rawRefreshAuthToken({
        api_token: authToken, refresh_token: refreshToken
      }).then(async data => {
        storage.setToken('refresh_token', data.refresh_token)
        storage.setToken('auth_token', data.api_token)

        prevRequest.headers.Authorization = data.api_token
        return await instance(prevRequest)
      }).catch(async (error2: RefreshAuthError) => {
        storage.removeToken('refresh_token')
        storage.removeToken('auth_token')
        return await Promise.reject(error2)
      })
    }

    return await Promise.reject(error)
  }
)

export default instance
