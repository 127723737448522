import React from 'react'
import Empty from '../Empty/Empty'
import Loader from '../../components/loader/Loader'
import { useTemplate as useTemplateApi } from '../../api/template'
import Template from './components/Template/Template'

const defaultTemplate = 1

const Container: React.FC = () => {
  const { data, isLoading, error } = useTemplateApi(defaultTemplate)

  if (isLoading) return <Loader />
  if (data) return <Template template={data} />
  return <Empty message={error?.response?.data.message} />
}

export default Container
