import * as React from 'react'
import { Typography } from '@mui/material'
import css from './PageHeaderText.module.css'

type PageHeaderTextProps = {
  children: React.ReactNode
  subtitle?: string | null
}

const PageHeaderText: React.FC<PageHeaderTextProps> = ({ children = '', subtitle = null }) => {
  return (
    <Typography variant="h4" className={css.text}>
        {children}
        {subtitle && (<span className={css.subtitle}>{subtitle}</span>)}
    </Typography>
  )
}

export default PageHeaderText
