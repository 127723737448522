import React from 'react'
import TextField from '@mui/material/TextField'
import { type FormikProps } from 'formik'
import { Stack } from '@mui/material'
import { type User as UserType } from '../../types'

export type EditableUserType = Pick<UserType, 'email'>

interface UserFormProps {
  formik: FormikProps<EditableUserType>
}

const UserForm: React.FC<UserFormProps> = ({ formik }) => {
  return (
    <Stack marginTop={2} spacing={2}>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        disabled={formik.isSubmitting}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />
    </Stack>
  )
}

export default UserForm
