import React from 'react'
import css from './CSDashboard.module.css'
import { Box, Tabs, Tab, Stack } from '@mui/material'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import CSStages from './CSStages/CSStages'
import CSContent from './CSContent/CSContent'

const tabs = [
  { label: 'Stages', value: 'stages' },
  { label: 'Content', value: 'content' }
]

const CSDashboard: React.FC = () => {
  const [tab, setTab] = React.useState(tabs[0].value)

  return (
    <div className={css.container}>
      <PageHeaderText>CS Dashboard</PageHeaderText>
      <Box display="flex" padding={4}>
        <Stack flex={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs value={tab} onChange={(event, value) => setTab(value)}>
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
        </Stack>
      </Box>
      {tab === 'stages' && <CSStages />}
      {tab === 'content' && <CSContent />}
    </div>
  )
}

export default CSDashboard
