import React, { useContext } from 'react'
import MessageContext from '../../contexts/MessageContext'
import { type Blog as BlogType } from '../../types'
import Empty from '../Empty/Empty'
import { Box, Button, Stack } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import { useUpdateBlog, useBlog } from '../../api/blog'
import pick from 'lodash/pick'
import { locale } from '../../constants'
import * as Yup from 'yup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Spinner from '../../components/spinner/Spinner'

type EditableBlogType = Pick<BlogType, 'name' | 'url'>

const editableBlogSchema = Yup.object({
  name: Yup.string().label('Name').required(),
  url: Yup.string().label('URL').required()
})

interface BlogProps {
  blog: BlogType
}

const BlogInfo: React.FC<BlogProps> = ({ blog }) => {
  const updateBlog = useUpdateBlog()
  const addMessage = useContext(MessageContext)
  const pluginVersion = blog.plugin_version

  const formik: FormikProps<EditableBlogType> = useFormik<EditableBlogType>({
    initialValues: {
      ...pick(blog, ['name', 'url'])
    },
    validationSchema: editableBlogSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updateBlog
        .mutateAsync({ id: blog.id, data: values })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.message || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <div>
      <Typography variant="body1" gutterBottom>
        Plugin version: {pluginVersion || '-'}
      </Typography>
      <Box display="flex">
        <Stack flex={4}>
          <form onSubmit={formik.handleSubmit}>
            <Stack marginTop={4} spacing={2}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                id="url"
                name="url"
                label="URL"
                value={formik.values.url}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.url && !!formik.errors.url}
                helperText={formik.touched.url && formik.errors.url}
              />
            </Stack>
            <Stack marginTop={2} alignItems="flex-end">
              <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
                Save
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
    </div>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  const { data, isLoading, error } = useBlog(blogId)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong Blog Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <BlogInfo blog={data} />
  return <Empty message={message} />
}
export default Container
