import * as React from 'react'
import { Box, FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { Plan } from '../../types'
import { useSetCompanyPlan } from '../../api/management'
import { useContext } from 'react'
import MessageContext from '../../contexts/MessageContext'

interface CompanyPlanAssignerProps {
  id: number
  value?: Plan
}

const CompanyPlanOptions = [
  {
    label: 'Starter',
    value: Plan.STARTER
  },
  {
    label: 'Basic',
    value: Plan.BASIC
  },
  {
    label: 'Pro',
    value: Plan.PRO
  }
]

const CompanyPlanAssigner: React.FC<CompanyPlanAssignerProps> = ({ id, value }) => {
  const addMessage = useContext(MessageContext)
  const setCompanyPlan = useSetCompanyPlan()

  const setPlan = (plan: Plan): void => {
    setCompanyPlan.mutateAsync({ company_id: id, plan }).catch((err: any) => {
      console.error(err)
      addMessage({
        id: Date.now(),
        message: 'Unable to update company plan'
      })
    })
  }

  return (
    <Box bgcolor="white">
      <FormControl fullWidth>
        <InputLabel id="company-plan-select-label">Plan</InputLabel>
        <Select
          labelId="company-plan-select-label"
          label={'Plan'}
          id="company-plan-select"
          name="company-plan-select"
          value={value ?? Plan.BASIC}
          onChange={(e) => {
            setPlan(e.target.value as Plan)
          }}
          variant="outlined"
          color="primary"
          size="medium"
          fullWidth={true}
        >
          {CompanyPlanOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CompanyPlanAssigner
