import React, { useContext } from 'react'
import { PERMISSION, ROUTES } from '../../types'
import AuthContext from '../../contexts/AuthContext'
import { NavLink } from 'react-router-dom'
import { ROUTES_LABELS } from '../../constants'
import css from './Header.module.css'
import cls from 'classnames'
import Logo from '../../assets/icons/Logo'

const MENU: { [key in PERMISSION]: React.ReactNode[] } = {
  [PERMISSION.ADMIN]: [
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.CONFIG} to={ROUTES.CONFIG}>
      {ROUTES_LABELS[ROUTES.CONFIG]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.USERS} to={ROUTES.USERS}>
      {ROUTES_LABELS[ROUTES.USERS]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.COMPANIES} to={ROUTES.COMPANIES}>
      {ROUTES_LABELS[ROUTES.COMPANIES]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.BLOGS} to={ROUTES.BLOGS}>
      {ROUTES_LABELS[ROUTES.BLOGS]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.TEMPLATES} to={ROUTES.TEMPLATES}>
      {ROUTES_LABELS[ROUTES.TEMPLATES]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.REQUESTS} to={ROUTES.REQUESTS}>
      {ROUTES_LABELS[ROUTES.REQUESTS]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.CONTENT} to={ROUTES.CONTENT}>
      {ROUTES_LABELS[ROUTES.CONTENT]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.REPORTING} to={ROUTES.REPORTING}>
      {ROUTES_LABELS[ROUTES.REPORTING]}
    </NavLink>,
    <NavLink className={({ isActive }) => cls(css.item, { [css.active]: isActive })} key={ROUTES.CS_DASHBOARD} to={ROUTES.CS_DASHBOARD}>
      {ROUTES_LABELS[ROUTES.CS_DASHBOARD]}
    </NavLink>
  ],
  [PERMISSION.MEMBER]: [],
  [PERMISSION.AUTHORIZED]: [],
  [PERMISSION.OWNER]: []
}

const Header: React.FC = (props) => {
  const { me, logout } = useContext(AuthContext)
  const role = me?.role as PERMISSION
  const items = MENU[role]

  return (
    <div className={css.container}>
      <Logo />
      <div className={css.items}>
        {items}
        <a
          className={cls(css.item, css.logout)}
          onClick={(e) => {
            e.preventDefault()
            logout()
          }}
        >
          Log Out
        </a>
      </div>
    </div>
  )
}

export default Header
