import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { type BlogReportCount, ROUTES } from '../../types'
import type { ColumnDef } from '@tanstack/react-table'
import css from './Reporting.module.css'

const monthColumns = _.range(12)
  .map((i) => moment().subtract(i, 'months').format('YYYY/MM'))
  .reverse()
  .map((month) => ({
    accessorKey: `report.${month.toLowerCase() as any}`,
    header: moment(month, 'YYYY/MM').format('MMM/YY'),
    cell: (cell: any) => {
      const activeData = cell.getValue()

      if (!activeData) {
        return '-'
      }

      const { draft, scheduled, published } = JSON.parse(JSON.stringify(activeData))
      return `${draft}/${published}/${scheduled}`
    }
  }))

const columns: Array<ColumnDef<BlogReportCount>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'name',
    header: 'Name',
    cell: (cell) => (
      <Link to={ROUTES.BLOGS + '/' + cell.row.original.id} target={'blank'} rel={'noopener noreferrer'} className={css.userLink}>
        {cell.getValue() as string}
      </Link>
    )
  },
  ...monthColumns
]

export default columns
