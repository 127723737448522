import React, { useState } from 'react'
import { useUsers } from '../../api/user'
import css from './Users.module.css'
import { ROUTES, type User } from '../../types'
import { Table, TableBody, TableRow, TableCell, TableHead, Pagination, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, IconButton } from '@mui/material'
import { type ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import UserStatusSwitch, { type UserStatusSwitchProps } from './UserStatusSwitch'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'

const LIMIT = 10

interface TableProps {
  data: User[]
}

const columns: Array<ColumnDef<User>> = [
  {
    accessorKey: 'id',
    header: 'User Id'
  },
  {
    accessorKey: 'email',
    header: 'Email'
  },
  {
    accessorKey: 'name',
    header: 'Name'
  },
  {
    accessorKey: 'phone',
    header: 'Phone'
  },
  {
    header: 'Account Status',
    accessorFn: (row) => ({ userId: row.id, checked: row.account_status === 'enabled' }),
    cell: (cell) => <UserStatusSwitch {...(cell.getValue() as UserStatusSwitchProps)} />
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.edit?.(cell.getValue() as number)
          }}
        >
          <Edit />
        </IconButton>
      </Box>
    )
  }
]

const TableUsers: React.FC<TableProps> = ({ data }) => {
  const navigate = useNavigate()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      edit: (id) => {
        navigate(ROUTES.USERS + '/' + id)
      }
    }
  })

  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Users: React.FC = (props) => {
  const navigate = useNavigate()
  const [limit, setLimit] = useState<number>(LIMIT)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const {
    data: { data, total } = { data: [] as User[], total: 0 },
    isLoading,
    error
  } = useUsers({
    limit,
    offset: (page - 1) * limit,
    search
  })

  // if (isLoading) return <Loader />

  return (
    <div className={css.container}>
      <PageHeaderText>Users</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput}>
          <TextField
            id="outlined-basic"
            label={'Name to search'}
            variant="outlined"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setSearch('')
            }}
          >
            Clear search
          </Button>
        </div>

        <div className={css.rightSideBlock}>
          <Button
            className={css.addNewButton}
            style={{ marginRight: '15px' }}
            variant={'contained'}
            onClick={() => {
              navigate(ROUTES.USERS + ROUTES.NEW)
            }}
          >
            Add User
          </Button>
          <div className={css.search_cpp}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Users per page</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                value={limit}
                label="Companies per page"
                onChange={(e) => {
                  setLimit(Number(e.target.value))
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <TableUsers data={data} />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
    </div>
  )
}

export default Users
