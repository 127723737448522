import { type Request, ROUTES } from '../../types'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Pageview } from '@mui/icons-material'
import { type ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Link } from 'react-router-dom'

const columns: Array<ColumnDef<Request<any>>> = [
  {
    accessorKey: 'id',
    header: 'Request Id'
  },
  {
    accessorKey: 'type',
    header: 'Request Type'
  },
  {
    accessorKey: 'company_id',
    header: 'Company Id',
    cell: (cell) => {
      const value = cell.getValue() as number
      return value ? (
        <Link target="_blank" rel="noopener noreferrer" to={`${ROUTES.COMPANIES}/${value}`}>
          {value}
        </Link>
      ) : (
        ''
      )
    }
  },
  {
    accessorFn: (row) => row.company.name,
    header: 'Company'
  },
  {
    accessorFn: (row) => JSON.stringify(row.request, null, 2),
    header: 'Request',
    cell: (cell) => <code style={{ maxHeight: 80, overflow: 'hidden' }}>{cell.getValue() as string}</code>
  },
  {
    accessorFn: (row) => JSON.stringify(row.content, null, 2),
    header: 'Content',
    cell: (cell) => <code style={{ maxHeight: 80, overflow: 'hidden' }}>{cell.getValue() as string}</code>
  },
  {
    accessorFn: (row) => moment(row.created_at).format('YYYY/MM/DD HH:mm:ss'),
    header: 'Created At'
  },
  {
    accessorKey: 'plugin_version',
    header: 'Plugin Version',
    cell: (cell) => {
      const value = cell.getValue() as string
      if (value) {
        const valueArr = value.split(':')
        return (
          <span>
            blogId:{' '}
            <Link target="_blank" rel="noopener noreferrer" to={`${ROUTES.BLOGS}/${valueArr[0]}`}>
              {valueArr[0]}
            </Link>
            {valueArr[1] ? `; ${valueArr[1]}` : ''}
          </span>
        )
      }

      return value
    }
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.view?.(cell.getValue() as number)
          }}
        >
          <Pageview />
        </IconButton>
      </Box>
    )
  }
]

export default columns
