import React from 'react'
import ReactJson, { type ReactJsonViewProps } from 'react-json-view'

const defaultProps: Partial<ReactJsonViewProps> = {
  theme: 'monokai'
}

const JSONView: React.FC<ReactJsonViewProps> = (props) => {
  const finalProps = {
    ...defaultProps,
    ...props
  }

  const handleCopy = (copy: any): void => {
    const str = JSON.stringify(copy.src, null, '\t')
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(str)
    } else {
      const el = document.createElement('textarea')
      el.value = str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }

  return <ReactJson enableClipboard={handleCopy} {...finalProps} />
}

export default JSONView
