import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { type CompanyContentHistory, ROUTES } from '../../types'
import type { ColumnDef } from '@tanstack/react-table'
import css from './Content.module.css'

const monthColumns = _.range(12)
  .map((i) => moment().subtract(i, 'months').format('YYYY/MM'))
  .reverse()
  .map((month) => ({
    accessorKey: `history.${month.toLowerCase() as any}`,
    header: moment(month, 'YYYY/MM').format('MMM/YY')
  }))

const columns: Array<ColumnDef<CompanyContentHistory>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'name',
    header: 'Name',
    cell: (cell) => (
      <Link to={ROUTES.COMPANIES + '/' + cell.row.original.id} target={'blank'} rel={'noopener noreferrer'} className={css.userLink}>
        {cell.getValue() as string}
      </Link>
    )
  },
  ...monthColumns,
  {
    accessorKey: 'total',
    header: 'Total'
  }
]

export default columns
