import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import {
  type RefreshAuthError,
  type RefreshAuthInput,
  type RefreshAuthResponse
} from '../types'
import instance from './client'

export const rawRefreshAuthToken = async (
  data: RefreshAuthInput,
  signal?: AbortSignal
): Promise<RefreshAuthResponse> => {
  return await instance.post(
    '/auth/refresh',
    data,
    { signal }
  ).then(data => data.data)
}

const useRefreshAuthToken = (
  signal?: AbortSignal
): UseMutationResult<RefreshAuthResponse, RefreshAuthError, RefreshAuthInput> => {
  return useMutation<RefreshAuthResponse, RefreshAuthError, RefreshAuthInput>({
    mutationFn: async (data) => await rawRefreshAuthToken(data, signal)
  })
}

export default useRefreshAuthToken
