import * as React from 'react'
import { Box, FormControl, type InputBaseProps, InputLabel, MenuItem, type SelectChangeEvent } from '@mui/material'
import Select from '@mui/material/Select'
import { ManagementRole } from '../../types'
import { useManagers } from '../../api/management'
import { useMemo } from 'react'

interface ManagerSelectorProps {
  value?: number | string | null
  role: ManagementRole
  label?: string
  onChange?: (role: number | undefined) => void
  size?: InputBaseProps['size']
  style?: React.CSSProperties
  emptyValueText?: string
}

const DefaultManagerLabels = {
  [ManagementRole.CS_MANAGER]: 'CS Manager'
}

const EMPTY_VALUE = '-'

const ManagerSelector: React.FC<ManagerSelectorProps> = ({ value, role, label, onChange = (v) => {}, size, style, emptyValueText }) => {
  const { data, isLoading, error } = useManagers(role)
  const finalLabel = label ?? DefaultManagerLabels[role as keyof typeof DefaultManagerLabels]

  const handleChange = (e: SelectChangeEvent): void => {
    if (e.target.value === EMPTY_VALUE) {
      onChange(undefined)
    } else {
      onChange(Number(e.target.value))
    }
  }

  const isValueInvalid = useMemo(() => {
    if (!value || isLoading) {
      return false
    }

    return !data?.find((manager) => manager.id === value)
  }, [value, data])

  return (
    <Box style={style ?? { width: '100%' }} bgcolor="white">
      <FormControl fullWidth>
        <InputLabel id="manager-select-label">{finalLabel}</InputLabel>
        <Select
          labelId="manager-select-label"
          label={finalLabel}
          id="manager-select"
          name="manager-select"
          value={value?.toString() ?? EMPTY_VALUE}
          onChange={handleChange}
          variant="outlined"
          size={size ?? 'medium'}
          fullWidth={true}
          error={isValueInvalid}
        >
          <MenuItem value={EMPTY_VALUE}>{emptyValueText ?? 'No manager'}</MenuItem>
          {(data ?? []).map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.email}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ManagerSelector
