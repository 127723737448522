import * as React from 'react'
import { useContext } from 'react'
import { ManagementRole } from '../../types'
import MessageContext from '../../contexts/MessageContext'
import ManagerSelector from '../managerSelector/ManagerSelector'
import { useSetCompanyManager } from '../../api/management'

interface CompanyManagerAssignerProps {
  id: number
  role: ManagementRole
  value: number | undefined | null
}

const CompanyManagerAssigner: React.FC<CompanyManagerAssignerProps> = ({ id, role, value }) => {
  const addMessage = useContext(MessageContext)
  const setCompanyManager = useSetCompanyManager()

  const setManager = (userId: number | undefined): void => {
    setCompanyManager.mutateAsync({ company_id: id, role, user_id: userId }).catch((err: any) => {
      console.error(err)
      addMessage({
        id: Date.now(),
        message: 'Unable to set company manager'
      })
    })
  }

  return <ManagerSelector role={ManagementRole.CS_MANAGER} value={value} onChange={setManager} />
}

export default CompanyManagerAssigner
